<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 text-center">Outil d'aide à la ré-écriture</p>
      <p class="ml-5">
        <a :href="`${post.url}`" class="mr-5">{{ post.url }}</a>
        <a
          style="text-decoration: none"
          target="_blank"
          :href="`${post.feedUrl}`"
          >↳</a
        >
      </p>

      <v-row>
        <v-col cols="6">
          <v-card-text>
            <v-text-field
              v-model="post.title"
              :label="`Titre (postID: ${post.id})`"
              outlined
              dense
            ></v-text-field>

            <v-btn
              @click="generateTitlesAndAbstracts"
              color="primary"
              elevation="4"
              :loading="titleAndAbstractLoading"
              :disabled="titleAndAbstractLoading"
            >
              Générer titres et abstracts
            </v-btn>

            <v-textarea
              outlined
              v-model="post.abstract"
              name="abstract"
              label="Abstract"
              height="70px"
              font-size="0.8rem"
              class="mt-5"
            ></v-textarea>

            <v-progress-linear
              indeterminate
              :active="contentLoading"
            ></v-progress-linear>

            <v-textarea
              outlined
              v-model="post.content"
              ref="postContentTextarea"
              height="330px"
              name="content"
              label="Corps du texte"
              font-size="0.8rem"
            ></v-textarea>

            <v-btn
              @click="generateParaphrases"
              color="primary"
              elevation="4"
              :loading="paraphraseLoading"
              :disabled="paraphraseLoading"
            >
              Paraphraser le texte sélectionné
            </v-btn>
          </v-card-text>
        </v-col>

        <v-col cols="6">
          <v-card-text>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>Titres</v-expansion-panel-header>
                <v-expansion-panel-content class="new-titles">
                  <div v-if="titles.length">
                    <div v-for="item in titles" class="item">
                      {{ item }}
                    </div>
                  </div>
                  <div v-else>Aucun</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Abstracts</v-expansion-panel-header>
                <v-expansion-panel-content class="new-abstracts">
                  <div v-if="abstracts.length">
                    <div v-for="item in abstracts" class="item">
                      {{ item }}
                    </div>
                  </div>
                  <div v-else>Aucun</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Paraphrases</v-expansion-panel-header>
                <v-expansion-panel-content class="paraphrases">
                  <div v-if="paraphrases.length">
                    <v-expansion-panels v-model="panelParaphrases">
                      <v-expansion-panel
                        v-for="paraphrase in paraphrases"
                        class="paraphrase"
                      >
                        <v-expansion-panel-header class="selection">
                          {{ paraphrase.selection }}</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="new-paraphrases">
                          <div
                            v-for="new_text in paraphrase.new_texts"
                            class="item"
                          >
                            {{ new_text }}
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div v-else>Aucune</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text class="mt-12">
            Créativité: {{ temperature }} / 10
            <v-slider
              v-model="temperature"
              dense
              hint="Im a hint"
              max="10"
              min="0"
              step="1"
              ticks="always"
              tick-size="4"
            ></v-slider>

            <v-expansion-panels
              ><!-- v-model="panelPrompts"-->
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Configurer les prompts</v-expansion-panel-header
                >
                <v-expansion-panel-content class="prompts">
                  <v-textarea
                    outlined
                    v-model="titleAndAbstract.promptSystem"
                    height="200px"
                    name="content"
                    label="Prompt pour les titres et abstracts"
                    font-size="0.8rem"
                  ></v-textarea>

                  <v-textarea
                    outlined
                    v-model="paraphrase.promptSystem"
                    height="200px"
                    name="content"
                    label="Prompt pour paraphraser"
                    font-size="0.8rem"
                    class="mt-6"
                  ></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "@axios";
import store from "@/store";

export default {
  name: "RepublishingDiscoverPostModalView",
  props: {
    postId: {
      type: Number,
      default: () => {
        return null;
      },
      validator(value) {
        let intValue = parseInt(value);
        return intValue == value && value >= 1;
      },
    },
    postUrl: {
      type: String,
      default: () => {
        return null;
      },
      validator(value) {
        return value != "";
      },
    },
  },
  data() {
    return {
      panel: [0, 1, 2],
      panelParaphrases: [],
      titleAndAbstract: {
        promptSystem: `Voici les instructions pour la réécriture du titre (title), à n'appliquer que pour le titre : 
Agis comme un journaliste d'un site d'actualité spécialisé dans l'édition d'articles et la création de titres optimisés pour Google Discover. 
Réécris ce titre en conservant l'idée principale du titre original, tout en le reformulant pour qu'il soit accrocheur stimule l'intérêt et la curiosité des lecteurs. 
Le titre doit être formulé selon les critères suivants :
Phrases Interrogatives ou Incomplètes : Utiliser des questions est possible mais principalement privilégier des phrases partielles pour inciter les lecteurs à découvrir la suite.
Chiffres et Statistiques Mis en Avant : Inclure des données chiffrées ou des statistiques pour renforcer l'autorité et la précision de l'information.
Appel à l'Autorité ou à l'Expertise : Citer des experts, des études, ou des sources reconnues pour légitimer les informations présentées.
Création de Mystère ou de Curiosité : Utiliser des éléments de mystère ou d'intrigue pour encourager les lecteurs à en savoir plus.
Adjectifs Impactants et Qualitatifs : Choisir des adjectifs forts pour un impact émotionnel ou descriptif significatif.
Référence à des Marques ou des Produits Concrets : Mentionner des marques ou des produits spécifiques pour créer un lien avec l'expérience des lecteurs.
Évocation d'Événements ou de Tendances Actuelles : Se référer à des événements récents ou des tendances pour assurer la pertinence et l'actualité.
Autres consignes importantes : Les titres doivent être naturels et fluides à lire, avec seulement une majuscule sur le premier mot. Il ne vaut absolument jamais utiliser deux-points en début de l'article, mais privilégier une virgule pour rendre la lecture simple. Également, il faut éviter d'utiliser le terme "découvrez", mais privilégier des synonymes comme "ce", ou "voici". Il faut aussi éviter la forme interrogative. Ils doivent être adaptés à divers sujets et types d'articles, pour un site visant à maximiser son audience sur des plateformes comme Google Discover. L'objectif est de créer un style de titraille distinctif et efficace pour attirer et engager les lecteurs.

Voici les instructions pour la réécriture de l'introduction (abstract), à n'appliquer que pour l'abstract : 
Acte comme journaliste expert SEO écrivant des articles optimisés pour le SEO. Ré-écris le chapo/méta description (abstract) de l'article avec un style informationnel et journalistique. Ne mentionne rien sur le SEO mais applique bien les guidelines.`,
      },
      paraphrase: {
        promptSystem: `Réécris / paraphrase le contenu fourni par l'utilisateur en te basant sur le texte original suivant. Le texte réécrit doit conserver les informations clés et le message principal du texte original, tout en utilisant une structure et des mots différents. Il est extrêmement important qu'on ne sache pas que c'est toi qui a rédigé ce texte.

Directives pour la réécriture :
- Utilisation de synonymes : remplace les mots-clés et expressions principales par des synonymes appropriés.
- Modification de la structure des phrases : Change la structure des phrases (ordre des mots, active/passive, etc.) tout en conservant leur sens.
- Variation des expressions : Utilise différentes expressions ayant le même sens pour diversifier le texte mais sans jamais le complexifier. Utilise des mots simples. Ne modifie absolument jamais les citations entre guillemets.
- Adaptation du contexte : si nécessaire, adapte le contexte pour le rendre plus actuel ou pertinent, sans changer le sujet original.
- Maintien de la clarté et de la cohérence : veille à ce que le texte réécrit reste clair, cohérent, fidèle au contenu original et dans un langage courant.
Utilise ces directives pour transformer le texte original en un nouveau contenu qui soit distinct mais fidèle à l'original.
        `,
      },
      temperature: 5,
      contentLoading: false,
      titleAndAbstractLoading: false,
      paraphraseLoading: false,
      textSelection: "",
      post: {
        id: this.postId,
        url: this.postUrl,
        title: "",
        abstract: "",
        content: "",
        feedUrl: "",
      },
      titles: [],
      abstracts: [],
      paraphrases: [],
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
    };
  },
  methods: {
    decodeHtml(html) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    getSelectedText() {
      const textarea = this.$refs.postContentTextarea.$refs.input; // accessing the textarea element
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const textSelection = this.post.content.substring(start, end);
      return textSelection;
    },
    async generateParaphrases() {
      const url = "/republishing-discover/generate-paraphrases";
      let textSelection = this.getSelectedText();
      textSelection = textSelection.trim();
      if (textSelection == "") {
        alert("Veuillez sélectionner du texte dans le corps de texte.");
        return;
      }

      const postParams = {
        prompt_system: this.paraphrasePromptSystem,
        prompt_user: textSelection,
        temperature: this.temperature ? this.temperature / 10 : 0,
      };
      this.paraphraseLoading = true;
      try {
        const response = await axios.post(url, postParams);
        this.paraphrases.push({
          selection: textSelection,
          new_texts: response.data.answer,
        });
      } catch (error) {
        console.warn(error);
      }
      this.paraphraseLoading = false;
    },
    async generateTitlesAndAbstracts() {
      const url = "/republishing-discover/generate-titles-and-abstracts";
      const postParams = {
        prompt_system: this.titleAndAbstract.promptSystem,
        prompt_user: this.promptUser,
        temperature: this.temperature ? this.temperature / 10 : 0,
      };
      this.titleAndAbstractLoading = true;
      try {
        const response = await axios.post(url, postParams);
        this.titles.push(...response.data.answer.title_list);
        this.titles = [...new Set(this.titles)];
        this.abstracts.push(...response.data.answer.abstract_list);
        this.abstracts = [...new Set(this.abstracts)];
      } catch (error) {
        console.warn(error);
      }
      this.titleAndAbstractLoading = false;
    },
    async getContentByWebScraping() {
      await store.dispatch("app/setSnackBar", {
        show: true,
        text: `Tentative de récupération du contenu par web scrapping.`,
        color: "primary",
        timeout: 2000,
      });
      const url = `https://ai.app.reworldmediafactory.com/web-tools/scrape-url?url=${this.postUrl}`;
      //"https://www.aufeminin.com/cuisine-equilibree/creme-chantilly-s752721.html";
      // const response = await axios.get(`/post/${this.postId}`);
      this.contentLoading = true;
      const response = await axios.get(url);
      this.contentLoading = false;
      if (response.status == 200) {
        this.post.title = response.data.title;
        let cleanedText = response.data.content_text;
        // cleanedText = cleanedText.replace(/^\s+$/gm, "");
        // cleanedText = cleanedText.replace(/^\s+\n/gm, "\n");
        cleanedText = cleanedText.replace(/^\n +/gm, "\n");
        cleanedText = cleanedText.replace(/\n{4,}/g, "\n\n\n");
        const sections = cleanedText.split(/\n\n\n/);
        const filteredSections = sections.filter((section) => {
          const wordCount = section.trim().split(/\s+/).length;
          return wordCount >= 2;
        });
        cleanedText = filteredSections.join("\n\n");
        this.post.content = cleanedText;
      }
    },
    async getContentFromFeed() {
      await store.dispatch("app/setSnackBar", {
        show: true,
        text: `Tentative de récupération du contenu via le flux par ID.`,
        color: "primary",
        timeout: 2000,
      });
      const postUrl = new URL(this.postUrl);
      const domainWithProtocol = `${postUrl.protocol}//${postUrl.host}`;
      this.post.feedUrl = `${domainWithProtocol}/feed/content/audience/${this.postId}`;
      this.contentLoading = true;
      const queryParams = {
        url: this.post.feedUrl,
      };
      const response = await axios.get("/audience/feed-content-by-url", {
        params: queryParams,
      });
      this.contentLoading = false;
      if (
        response.status == 200 &&
        "data" in response &&
        "titre" in response.data
      ) {
        this.post.title = this.decodeHtml(response.data.titre); // .replace(/&quot;/g, '"');
        this.post.content = this.decodeHtml(response.data.content);
        return true;
      }
      return false;
    },
    async init() {
      this.post = {
        id: this.postId,
        url: this.postUrl,
        title: "",
        abstract: "",
        content: "",
        feedUrl: "",
      };
      this.titles = [];
      this.abstracts = [];
      this.paraphrases = [];
      if (this.postId && this.postUrl) {
        let success = await this.getContentFromFeed();
        if (!success) {
          this.getContentByWebScraping();
        }
      } else {
        console.warn(`no id and/or no url in components params`);
      }
    },
  },
  watch: {
    postId: async function (newVal, oldVal) {
      // console.log("Prop postId changed: ", newVal, " | was: ", oldVal);
      this.init();
    },
  },
  computed: {
    promptUser() {
      return `
### TITRE DE L'ARTICLE:
${this.post.title}

### ABSTRACT DE L'ARTICLE:
${this.post.abstract}

### CONTENU DE L'ARTICLE:
${this.post.content}
      `;
    },
    paraphrasePromptSystem() {
      return `${this.paraphrase.promptSystem}

### TITRE DE L'ARTICLE:
${this.post.title}

### ABSTRACT DE L'ARTICLE:
${this.post.abstract}

### CONTENU DE L'ARTICLE:
${this.post.content}
        `;
    },
  },
  mounted() {},
  async created() {
    // console.log(`this.postId:`, this.postId);
    // console.log(`this.postUrl:`, this.postUrl);
    this.init();
  },
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
};
</script>
<style scoped>
.new-titles .item:not(:last-child),
.new-abstracts .item:not(:last-child),
.new-paraphrases .item:not(:last-child) {
  border-bottom: 1px solid rgba(94, 86, 105, 0.68);
}
.pre-for-textarea {
  width: 100%;
  display: block !important;
  word-break: break-all !important;
  word-wrap: break-word !important;
  overflow-wrap: anywhere !important;
  border-radius: 5px;
  border: 1px solid rgb(192, 192, 192, 0.5);
  padding: 5px;
}
</style>
